
/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: BaseEnv.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/BaseEnvironments/BaseEnv.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 20/03/24, 11:28;
 * Updated at: 20/03/24, 11:28;
 *
 *
 */
const Menu:{module?:string, path?:string, title?:string, icon?:string, class?:string, capacita?:string[], submenu?:any[], label?:string, extralink?:boolean }[] = [
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampas","menu-blocchis"],//todo capacita per az dumb
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        },
        {
          path:"/blocchi/blocchi/list",
          title: "Blocchi",
          capacita: ["menu-blocchi"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruolis"]//todo capacita per az dumb
    },
  ];

export const BaseEnv  = {
    Wp: false,
    hasCustomizer:false,
    production: true,
    apiUrl: '',
    bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
    langPrefix: './assets/i18n/',
    langSuffix: '_Progetti.json',
    loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
    icon:'assets/images/KlimaIMGxicon.png',
    baseUrl: '/task/unita',
    href: '/Login',
    homepages: [
      // {Ruoli: ["AC"], link: "/Cliente/profile"}
    ],
    logoPath: 'assets/images/FF_Logo.png',
    loginMsg: '',
    logoBg: 'skin5', // six possible values: skin(1/2/3/4/5/6),
    navbarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    userCircleColor: "",
    theme: 'dark',
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    ClientiType: ['C', 'F'],
    IDTecnico: "RT",
    //#region Moduli
    Modules:{
      "twoFactorAuth": true,
      "Admin": true,
      "Personale": true,
      "Clienti": true,
      "Interventi": false,
      "Prima_Nota": false,
      "Listini": false,
      "Progetti": false,
      "Prodotti": false,
      "Documenti": false,
      "Scadenzario": false,
      "Spedizioni": false,
      "Stampe": false,
      "Task": true,
      "Shared": false,
      "Dashboard": false,
      "Settings": true,
      "Formazione": false,
      "PartiProdotto": false,
      "Parti": false,
      "Rma": false,
      "Ecommerce": false,
      "ScadenzeFiglie": false,
      "Registrazione": false,
      "Ticket": false,
      "Acquisti": false,
      "taskboard": false,
      "Camera": false,
      "Valutazioni":false,
      FoodCost: false
    },
    //#endregion
    //#region Addon
    TaskAddon: {
      checkList : true,
      sidebarFilter: [
        {
          nome: 'Cliente',
          source: '/anagrafica/clienti/list',
          parameter: [{nome:"page", value: 1}, {nome:"size", value: 2000}],
          responseHasData: true,
          oggetti: ['cliente'],
          internalOutput: 'clienteObject',
          OutputField: 'cliente',
          OnChange: 'OnClienteChange',
          modificabile: true,
          inputType: 'singleDropdown',
          placeholder: 'Seleziona un Cliente',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'nominativo',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: false,
          },
          class: 'col-lg-12',
          visibleifProhibited: true
        },
        {
          nome: 'Responsabile',
          source: '/anagrafica/persone/list',
          parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
          responseHasData: true,
          oggetti: ['manager'],
          internalOutput: 'managerObject',
          OutputField: 'persona',
          OnChange: 'OnManagerChange',
          OnFilter: 'filtroManager',
          modificabile: true,
          inputType: 'singleDropdown',
          allowedCapacity: ['gestione-rma'],
          placeholder: 'Seleziona un Responsabile',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          class: 'col-lg-12',
          visibleifProhibited: false
        },
        {
          modificabile: true,
          nome: 'Stato',
          oggetti: ['chiuso'],
          inputType: 'singleDropdown',
          source: [{id:true,label: 'chiuso'},{id:false,label: 'aperto'},{id:null,label: 'Tutti'}],
          internalOutput: 'chiusoObject',
          OutputField: 'chiuse',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput:true,
            idField: 'id',
            textField: 'label',
          },
          titleClass: 'control-label col-form-label'  ,
          class: 'col-lg-12',
          visibleifProhibited: true,
        },
      ],
    },
    Personale:{
      Corsi: true,
      Attivita: true,
      File: true,
    },
    ClientiAddOn: {
      codAdhoc:false,
      enable:false,
      info:true,
      commerciale: false,
      segnalatore: false,
      segnalatoreInterno: false,
      sedi: false,
      TipoSedePrimaria: false,
      attivita: false,
      geolocalizzazione: false,
      ruolo: true,
      Modifica: false,
      prodotti: false,
      rma: false,
      accesso: false,
      referenti: false,
      interventi: false,
      note_private:false,
      rating : false,
      ruoliAccesso:false,
      compilazione:false,
      luogoNascita: false,
      dataNascita: false,
      statoCivile:false,
      titoloStudio: false,
      occupazione: false,
      invalidita: false,
      legge104: false,
      diagnosi: false,
      accompagnamento:false,
      pensione: false,
      tutelare: false,
      medico: false,
      telMedico: false,
      terapia:false
    },
    ProdottiAddOn: {
      sottoTitolo: false,
      shortDescription: false,
      classeEnergetica: false,
      tags: false,
      video: false,
      dettaglio:false,
      files: true,
      cart: false,
      codice: false,
      codice_produttore: false,
      tipo: true,
      prezzoSingolo: true,
      standby: false,
      tipi:false, // tipi di prodotto (1 - 2ante klima)
      cat_image: true,
      lista_prezzo:true,
      TranslatedDesc: false,
      codAdhoc:false,
      DragSort: false,
      cat_Sort: false
    },
    legenda:[],
    filtrife: [
      {id:"T", label: "Tutti", capacita: [], module: undefined},
      {id:"R", label: "RMA", capacita: [], module: undefined},
      {id:"A",label:"Attivita", capacita: [], module: undefined},
      {id:"C",label:"Attivita Cliente", capacita: [], module: undefined},
      {id:"S", label: "Scadenze", capacita: [], module: undefined},
      {id:"F",label:"Fasi", capacita: [], module: undefined},
      {id:"L", label: "Lezioni", capacita: [], module: undefined},
      {id:"B",label:"Spese", capacita: [], module: undefined},
      {id:"I", label: "Fatture", capacita: [], module: undefined},
      {id:"V", label: "Valutazioni", capacita: [], module: undefined},
      {id:"W", label: "SottoTask Segnalazione", capacita: [], module: undefined},
      {id:"N", label: "News", capacita: [], module: undefined}
    ],
    CalendarAddon: {
      managerScadenza : true,
    },
    PartiAddOn: [false],//  categorie associate
    RmaAddOn: {
      taskGenerico: true,
      vociDiCosto: false,
      provaAcquisto:true,
      CategoriaProdotto: true,
      useTasklistInClienti: true,
      TipoAcquisto: false,
      TipoAcquistoSource: "ClientiType",
      Rivenditore:false,
      NumeroDocumento: false,
      DataDocumento: false,
      Ricevuta: false,
      Targhetta: false,
      CircostanzaDifetto: false,
      DescrizioneDifetto: false,
      Difetto: true,
      Telefono: true,
      Garanzia: true,
      EstensioneGaranzia: true,
      Allegati: true,
      Modello: true,
      Matricola: true,
      Brand: true,
      sottoTask: true,
      headersScadenza:[
        {nome: "Titolo", oggetti: ["nome"]},
        {nome: "Responsabile", oggetti: ['manager'], type: "object", field:['fullName'] },
        {nome: "Chiuso", oggetti: ['data_fine_effettiva'], inputType: 'datetime-local', type: "boolean"},
      ],
      AzioniScadenza:[

        {nome: '', class:' ',  labelClass:'ml-1 text-orange font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/prendiincarico', capacita: [], icon:'fas fa-user text-orange', placeholder: 'Prendi in carico', successMex: "Preso in carico", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: true}]},
        {nome: '', class:' ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-taskboard'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},

      ],
      campiScadenza: [
        {
          modificabile: true,
          nome: 'Responsabile',
          oggetti: ['manager'],
          inputType: 'singleDropdown',
          source: '/anagrafica/persone/list',
          responseHasData: true,
          parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
          internalOutput: 'managerObject',
          OnFilter: 'xx',
          OnChange: 'xxy',
          OutputField: 'manager',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          class: 'col-lg-3',
          visibleifProhibited: true,
        },
        {nome: "Files", type: "Files", url:"/rma/segnalazione/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange'},
      ],
      Ritiro: {
        Enabled: false,
        Referente: true,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true
      },
      Cliente: {
        Enabled: false,
        Referente: true,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true
      },
      Assistenza: {
        Enabled: false,
        Referente: true,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true,
        Tecnico: false,
      }
    },
    ProgettiAddon: {
      commerciale: true,
      segnalatore: true,
      categoriaVoceDiCosto: false,
      lavorato: true,
      badges:[
        {nome:'Non Accettate', oggetti:['nonAccettate'], },
        {nome:'Accettate', oggetti:['Accettate'], },
        {nome:'Completate', oggetti:['chiuse']},
        {nome:'Annullate', oggetti:['annullate']},
      ],
      filters: [
        {nome:'Completate', oggetti:['chiusi'], modificabile:true, inputType: 'checkbox', class: 'col-lg-3'},
        {nome:'Annullate', oggetti:['annullati'], modificabile:true, inputType: 'checkbox', class: 'col-lg-3'},
      ],
      CampiOfferta: [
        {
          modificabile: true,
          nome: 'Offerta.Responsabile',
          oggetti: ['manager'],
          inputType: 'singleDropdown',
          source: 'managers',
          internalOutput: 'managerObject',
          OnFilter: 'filtroManager',
          OnChange: 'OnManagerChange',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'cliente.commerciale',
          oggetti: ['commerciale'],
          inputType: 'singleDropdown',
          source: 'managers',
          internalOutput: 'commerciale',
          OnFilter: 'filtroCommerciale',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'cliente.segnalatore',
          oggetti: ['clienti'],
          inputType: 'singleDropdown',
          source: 'clienti',
          internalOutput: 'segnalatore',
          OnFilter: 'filtrosegnalatore',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'nominativo',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
            noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
            moreButton: true,
            moreButtonText: "Cerca in AdHoc",
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Colore',
          oggetti: ['colore'],
          inputType: 'color',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-1',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Lavorato',
          oggetti: ['lavorato'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-1',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Oggetto:',
          oggetti: ['oggetto'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Numero Ordine:',
          oggetti: ['codice'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          placeholder: 'Numero Ordine:',
          visibleifProhibited: true
        },
        {
          prevDivClass: "col-md-12",
          modificabile: true,
          nome: 'Offerta.Creazione',
          oggetti: ['data_creazione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Offerta',
          oggetti: ['data_emissione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Accettazione',
          oggetti: ['data_accettazione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Conferma',
          oggetti: ['data_conferma'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          prevDivClass: "col-md-12",
          modificabile: true,
          nome: 'Completato:',
          oggetti: ['chiuso'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
        },
        {
          modificabile: true,
          nome: 'Annullato:',
          oggetti: ['annullato'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
        },
        {
          modificabile: true,
          nome: 'Data Annullamento:',
          oggetti: ['dataAnnullato'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-2',
          visibleifProhibited: true,
        },
        {
          prevDivClass: "col-md-12",
          modificabile: true,
          nome: 'Note',
          oggetti: ['note'],
          inputType: 'textarea',
          titleClass: 'control-label col-form-label',
          class: 'col-md-6',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Note Private',
          oggetti: ['note_private'],
          inputType: 'textarea',
          titleClass: 'control-label col-form-label',
          class: 'col-md-6',
          visibleifProhibited: false
        },

        //   {
        //   modificabile:true,
        //   nome: 'Gestore',
        //   oggetti: ['manager'],
        //   inputType: 'singleDropdown',
        //   source: 'managers',
        //   internalOutput: 'gestoreObject',
        //   OnFilter: 'filtroCommerciale',
        //   placeholder: 'Seleziona',
        //   settings: {
        //     singleSelection: true,
        //     closeDropDownOnSelection: true,
        //     singleOutput:true,
        //     idField: 'id',
        //     textField: 'fullName',
        //     searchPlaceholderText: "inserire almeno 3 caratteri",
        //     allowSearchFilter: true,
        //     allowRemoteDataSearch: true,
        //   },
        //   titleClass: 'control-label col-form-label'  ,
        //   class: 'col-lg-3',
        //   visibleifProhibited: true
        //
        // },
      ],
      CampiSottoOfferta: []
    },
    //#endregion
    //#region Menu
    Menu: Menu
    //#endregion


}



